import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/Nevbar.css";
import nevlogo from "../assets/images/cbip-incold.png";
import power from "../assets/images/ministryOfPower.png";
import jalShakti from "../assets/images/jal-shakti-new.png";
import ashok from "../assets/images/ashok-logo.png";
import { FaChevronDown, FaChevronUp, FaBars, FaTimes, FaChevronRight  } from "react-icons/fa";
import Icold from "../assets/images/Icold.png";
const ICOLD_BROCHURE_URL = "https://icold.cloudbin.in/home/icoldcl/files.icold.cloudbin.in/ICOLD_BROCHURE.pdf";
const FULL_PAPER_TEMPLATE_URL = "https://icold.cloudbin.in/home/icoldcl/files.icold.cloudbin.in/Template_Full_Paper.docx";
const EXHIBITOR_MANUAL_URL = "https://icold.cloudbin.in/home/icoldcl/files.icold.cloudbin.in/exhibitorManual.pdf";

const handleClick = () => {
  // Open the image in a new tab
  window.open(Icold, '_blank');
};

function Nevbar() {
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdowns, setShowDropdowns] = useState({
    generalInfo: false,
    program: false,
    exhibitors: false,
    accommodation: false,
    tours: false,
    author: false,
    tailingDam: false,
  });

  const toggleDropdown = (dropdown) => {
    setShowDropdowns((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        if (key === dropdown) {
          acc[key] = !prevState[key];
        } else if (key === "program" && dropdown === "tailingDam") {
          acc[key] = true;
        } else {
          acc[key] = false;
        }
        return acc;
      }, {}),
    }));
  };

  const closeAllDropdowns = () => {
    setShowDropdowns({
      generalInfo: false,
      program: false,
      exhibitors: false,
      accommodation: false,
      tours: false,
      author: false,
      tailingDam: false,
    });
  };

  const handleClickOutside = (event) => {
    let targetElement = event.target;
    let isDropdown = false;

    while (targetElement) {
      if (targetElement.classList && targetElement.classList.contains("dropdown")) {
        isDropdown = true;
        break;
      }
      targetElement = targetElement.parentElement;
    }

    if (!isDropdown) {
      closeAllDropdowns();
    }
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const handleDropdownItemClick = (downloadUrl) => {
    if (downloadUrl) {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = downloadUrl.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    closeMenu();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const getArrowIcon = (dropdown) => {
    return showDropdowns[dropdown] ? <FaChevronUp /> : <FaChevronDown />;
  };

  return (
    <>
      <style>
        {`
          @keyframes colorChange {
             25% { background-color: #FBFF00; }
            50% { background-color: #00FFF2; }
            75% { background-color: #FACED6; }
          }

          .color-change-button {
            animation: colorChange 2s infinite;
          }
        `}
      </style>
      <div className="head" style={{ borderBottom: "1px solid rgba(169, 169, 169, 0.5)" }}>
        <div className="start">
          <img src={power} alt="Image 1" />
          <img src={jalShakti} alt="Image 2" className="image2" />
        </div>
        <div className="cent">
          <h1>
            <span style={{ fontWeight: "normal" }}>ICOLD</span>{" "}
            <span style={{ fontWeight: "800", fontSize: "30px" }}>2024</span>
          </h1>
        </div>
        <div className="end">
          <p className="line1">
            Days to <br />
            Register
          </p>
          <p className="line2">
            <span style={{ fontWeight: "700", fontSize: "38px" }}>146</span>{" "}
            <span style={{ fontWeight: "700", fontSize: "18px" }}>Days</span>
          </p>
          <img
            src={ashok}
            alt="Image 3"
            style={{
              backgroundColor: "#3e4095",
              paddingTop: "3px",
              paddingRight: "20px",
              paddingBottom: "3px",
              paddingLeft: "20px",
            }}
          />
        </div>
      </div>

      <nav className="nevbar">
        <div className="start" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", position:'sticky' }}>
          <img src={nevlogo} alt="ICOLD Logo" className="icold-logo" />
          <div className="hamburger-icon" onClick={toggleMenu}>
            {showMenu ? <FaTimes /> : <FaBars />}
          </div>
        </div>

        <div className="center">
          <ul className={showMenu ? "show-menu" : ""}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="dropdown">
              <div onClick={() => toggleDropdown("generalInfo")}>
                General Info{getArrowIcon("generalInfo")}
              </div>
              {showDropdowns.generalInfo && (
                <ul>
                  <li>
                    <Link to="/president_invitation" onClick={closeMenu}>
                      ICOLD President Invitation
                    </Link>
                  </li>
                  <li>
                    <Link to="/secretary_general" onClick={closeMenu}>
                      Invitation from the Secretary General, ICOLD
                    </Link>
                  </li>
                  <li>
                    <Link to="/advisory_committee" onClick={closeMenu}>
                    ICOLD 2024  International Advisory Committee
                    </Link>
                  </li>
                  <li>
                    <Link to="/organizing_committee" onClick={closeMenu}>
                      ICOLD 2024 Organizing Committee
                    </Link>
                  </li>
                  <li>
                    <Link to="/technical_committee" onClick={closeMenu}>
                    ICOLD 2024 Technical Committee
                    </Link>
                  </li>
                  <li>
                    <Link to="/president_chairman" onClick={closeMenu}>
                      INCOLD President And Chairman Invitation
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => handleDropdownItemClick(ICOLD_BROCHURE_URL)}>
                      ICOLD Brochure
                    </Link>
                  </li>
                  <li>
                    <Link to="/capital_city" onClick={closeMenu}>
                      Capital City - New Delhi
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" onClick={closeMenu}>
                      About Venue
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="dropdown">
              <div onClick={() => toggleDropdown("program")}>
                Program{getArrowIcon("program")}
              </div>
              {showDropdowns.program && (
                <ul>
                  <li>
                    <Link to="/program_schedule" onClick={closeMenu}>
                      Program Schedule
                    </Link>
                  </li>
                  <li>
                    <Link to="/icold_members" onClick={closeMenu}>
                      ICOLD Members
                    </Link>
                  </li>
                  <li>
                    <Link to ="/Technical_Workshops" onClick={closeMenu}>
                    ICOLD Technical Committee Workshops and Program Schedule</Link>
                  </li>
                  <li>
                    <Link to="/symposium_workshop" onClick={closeMenu}>
                      Symposium & Workshops
                    </Link>
                  </li>
                  <li className="dropdown">
                    <div onClick={() => toggleDropdown("tailingDam")} style={{ display: "flex", alignItems: "center" }}>
                      Program Tailing Dam committee - L{getArrowIcon("tailingDam")}
                    </div>
                    {showDropdowns.tailingDam && (
                      <ul className="nested-dropdown-list">
                        <li>
                          <Link to="/short_course" onClick={closeMenu}>
                            Short Course on Surveillance and Monitoring for Tailings and Embankment Dams
                          </Link>
                        </li>
                        <li>
                          <Link to="/october_2nd_workshop" onClick={closeMenu}>
                            October 2nd Workshop: Design of Tailings Dams for Closure
                          </Link>
                        </li>
                        <li>
                          <Link to="/bulletin_194" onClick={closeMenu}>
                            Workshop: Bulletin 194 - Tailings Dams Safety
                          </Link>
                        </li>
                        <li>
                          <Link to="/technical_tour" onClick={closeMenu}>
                            Technical Tour
                          </Link>
                        </li>
                        <li>
                          <Link to="/RegistrationOnline" onClick={closeMenu}>
                            Register Online
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link to="/sponsors">Sponsors</Link>
            </li>
            <li className="dropdown">
              <div onClick={() => toggleDropdown("exhibitors")}>
                Exhibitors{getArrowIcon("exhibitors")}
              </div>
              {showDropdowns.exhibitors && (
                <ul>
                  <li>
                    <Link to="/benefits" onClick={closeMenu}>
                      Benefits
                    </Link>
                  </li>
                  <li>
                    <Link to="/layout" onClick={closeMenu}>
                      Layout
                    </Link>
                  </li>
                  <li>
                    <Link to="/existing_exhibitors" onClick={closeMenu}>
                      Existing Exhibitors
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => handleDropdownItemClick(EXHIBITOR_MANUAL_URL)}>
                      Exhibitors Manual
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link to="/fee_details" onClick={closeMenu}>
                Fee Details
              </Link>
            </li>
            <li>
              <Link to="/contacts" onClick={closeMenu}>
                Contacts
              </Link>
            </li>
            <li className="dropdown">
              <div onClick={() => toggleDropdown("author")}>
                Author{getArrowIcon("author")}
              </div>
              {showDropdowns.author && (
                <ul>
                  <li>
                    <Link to="#" onClick={() => handleDropdownItemClick(FULL_PAPER_TEMPLATE_URL)}>
                      Full Paper Template
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="dropdown">
              <div onClick={() => toggleDropdown("accommodation")}>
                Accommodation{getArrowIcon("accommodation")}
              </div>
              {showDropdowns.accommodation && (
                <ul>
                  <li>
                    <Link to="/hotels" onClick={closeMenu}>
                      Hotels
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="dropdown">
              <div onClick={() => toggleDropdown("tours")}>
                Tours{getArrowIcon("tours")}
              </div>
              {showDropdowns.tours && (
                <ul>
                  <li>
                    <Link to="/pre_conference" onClick={closeMenu}>
                      Pre-Conference Tours
                    </Link>
                  </li>
                  <li>
                    <Link to="/post_conference" onClick={closeMenu}>
                      Post-Conference Tours
                    </Link>
                  </li>
                  <li>
                    <Link to="/excursion" onClick={closeMenu}>
                      Excursion Tours
                    </Link>
                  </li>
                  <li>
                    <Link to="/local_sightseeing" onClick={closeMenu}>
                      Local Sightseeing
                    </Link>
                  </li>
                  <li>
                    <Link to="/accompanying_person" onClick={closeMenu}>
                      Accompanying Person Tours
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="login-button">
              <Link to="/login">Login</Link>
            </li>
            <li className="register-button">
              <Link to="/register">Register</Link>
            </li>
          </ul>
          
        </div> 
      </nav>
      
    </>
  );
}

export default Nevbar;
