import React from "react";
import { Grid, Typography, Box, Divider } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import CIGB from "../assets/images/CIGB.png";
import Flower from "../assets/images/flower.png";
import Drip from "../assets/images/drip1.png";
import INCOLD from "../assets/images/icold-footer-logo.png";
import NHPC from "../assets/images/NHPC.png";
import AndroidImg from '../assets/images/android.png'
import AppleImg from '../assets/images/apple.png'
import tnalogo from '../assets/images/tnalogo.png'
import hydropower from '../assets/images/hydropower-and-dams.png'

function Footer() {
  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"  // Center content horizontally
        alignItems="center"      // Center items vertically
        sx={{ backgroundColor: "#f0f2f5", paddingTop: '30px' }} // Allow horizontal scrolling if needed
      >
       <Grid item xs={12} sm={6} md={2.4}> {/* Flexible width with max limit */}
  <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
    <Grid item xs={12} sx={{ textAlign: "center" }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8%' }}>
        <Divider sx={{ bgcolor: 'red', width: '15%', height: 1 }} /> {/* Adjust width to reduce length */}
        <Typography variant="h7" sx={{ mx: 2, fontSize: '16px',fontWeight:'600' }}>Organized & Hosted By</Typography> 
        <Divider sx={{ bgcolor: 'red', width: '15%', height: 1 }} /> {/* Adjust width to reduce length */}
      </Box>
      <Box className="image-container" sx={{ borderRight: '1px solid black', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {/* Reduce height */}
        <a href="https://www.icold-cigb.org/" target="_blank" rel="noopener noreferrer">
          <img src={CIGB} alt="CIGB Logo" className="cigb-logo" style={{ marginRight: '2px', height: '80px', width: 'auto' }} /> {/* Reduce image size */}
        </a>
        <a href="http://incold.co.in/" target="_blank" rel="noopener noreferrer">
          <img src={INCOLD} alt="INCOLD Logo" className="incold-logo" style={{ marginLeft: '2px', height: '100px', width: 'auto' }} /> {/* Reduce image size */}
        </a>
      </Box>
    </Grid>
  </Grid>
</Grid>


        <Grid item xs={12} sm={6} md={2.4}> {/* Flexible width with max limit */}
          <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8%' }}>
               <Divider sx={{ bgcolor: 'red', width: '20%', height: 1 }} /> {/* Adjust width to reduce length */}
                <Typography variant="h7" sx={{ mx: 2, fontSize: '16px',fontWeight:'600' }}>In Collaboration with</Typography> {/* Reduce font size */}
               <Divider sx={{ bgcolor: 'red', width: '20%', height: 1 }} /> {/* Adjust width to reduce length */}
              </Box>
              <Box className="image-container" sx={{ borderRight: '1px solid black', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {/* Reduce height */}
              <a href="https://cwc.gov.in/" target="_blank" rel="noopener noreferrer">
                <img src={Flower} alt="Flower Logo" className="flower-logo" style={{ marginRight: '2px', height: '100px', width: 'auto' }} /> {/* Reduce image size */}
                </a>
                <a href="https://damsafety.cwc.gov.in/damsafety/index.php" target="_blank" rel="noopener noreferrer">
                <img src={Drip} alt="Drip Logo" className="drip-logo" style={{ marginLeft: '2px', height: '100px', width: 'auto' }} /> {/* Reduce image size */}
                </a>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}> {/* Flexible width with max limit */}
          <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8%' }}>
               <Divider sx={{ bgcolor: 'red', width: '20%', height: 1 }} /> {/* Adjust width to reduce length */}
                <Typography variant="h7" sx={{ mx: 2, fontSize: '16px',fontWeight:'600' }}>Co-organizer</Typography> {/* Reduce font size */}
               <Divider sx={{ bgcolor: 'red', width: '20%', height: 1 }} /> {/* Adjust width to reduce length */}
              </Box>
              <Box className="image-container" sx={{ borderRight: '1px solid black', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {/* Reduce height */}
              <a href="https://www.nhpcindia.com/" target="_blank" rel="noopener noreferrer">
                <img src={NHPC} alt="NHPC Logo" className="nhpc-logo" style={{ marginRight: '5px', height: '80px', width: 'auto' }} /> {/* Reduce image size */}
                </a>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}> {/* Flexible width with max limit */}
          <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8%' }}>
               <Divider sx={{ bgcolor: 'red', width: '20%', height: 1 }} /> {/* Adjust width to reduce length */}
                <Typography variant="h7" sx={{ mx: 2, fontSize: '16px',fontWeight:'600' }}>Supported by</Typography> {/* Reduce font size */}
               <Divider sx={{ bgcolor: 'red', width: '20%', height: 1 }} /> {/* Adjust width to reduce length */}
              </Box>
              <Box className="image-container" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid black', height: '150px' }}> {/* Reduce height */}
                <Typography sx={{ fontSize: '18px' }}>Ministry of Power</Typography> {/* Reduce font size */}
                <Typography sx={{ fontSize: '18px' }}>Ministry of Jal Shakti</Typography> {/* Reduce font size */}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={2.4}> {/* Flexible width with max limit */}
          <Grid container spacing={2} sx={{ paddingBottom: '20px' }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8%' }}>
               <Divider sx={{ bgcolor: 'red', width: '20%', height: 1 }} /> {/* Adjust width to reduce length */}
                <Typography variant="h7" sx={{ mx: 2, fontSize: '16px',fontWeight:'600' }}>Media Partner</Typography> {/* Reduce font size */}
               <Divider sx={{ bgcolor: 'red', width: '20%', height: 1 }} /> {/* Adjust width to reduce length */}
              </Box>
              <Box className="image-container" sx={{ borderRight: '1px solid black', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {/* Reduce height */}
              <a href="https://www.icold2024.org/#/home" target="_blank" rel="noopener noreferrer">
                <img src={tnalogo} alt="TNA Logo" className="flower-logo" style={{ marginRight: '2px', height: '100px', width: 'auto' }} /> {/* Reduce image size */}
                </a>
                <a href="https://www.icold2024.org/#/home" target="_blank" rel="noopener noreferrer">
                <img src={hydropower} alt="Hydropower Logo" className="drip-logo" style={{ marginLeft: '2px', height: '100px', width: 'auto' }} /> {/* Reduce image size */}
                </a>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ backgroundColor: "#3e4095", paddingTop: 2 }}>
        <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' }, paddingLeft: 2 }}>
          <Typography variant="body2" sx={{ marginTop: 2, color: 'white', marginLeft: { xs: 0, sm: '3%' }, marginBottom: '2.5%', fontSize: '20px' }}>
            © Copyright 2023 - 2024 All Rights Reserved. Ministry of Power.
          </Typography>
          <Typography variant="body2" sx={{ textAlign: { xs: 'center', sm: 'left' ,marginLeft:'3%'} }}>
            <Link to="/PrivacyPolicy" style={{ color: "#ffe500", textDecoration: 'underline', fontSize: '18px' }}>
              Privacy Policy
            </Link>
            {" "}
            |
            {" "}
            <Link to="/TermsAndCondition" style={{ color: "#ffe500", textDecoration: 'underline', fontSize: '18px' }}>
              Terms & Conditions
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'right' }, paddingRight: 2, marginTop: '1%' }}>
          <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' }, alignItems: 'center' }}>
          <a href="https://play.google.com/store/apps/details?id=com.app.icold.icold" target="_blank" rel="noopener noreferrer">
              <img src={AndroidImg} alt="Android App" style={{ marginRight: 8 }} />
            </a>
            <img src={AppleImg} alt="Apple App" style={{ marginRight: 8 }} />
          </Box>
          <Typography variant="body2" sx={{ color: 'white', fontSize: '18px' , marginBottom:'3%'}}>
            Design & Developed by <a style={{ color: '#ffe500' }} href="https://triazinesoft.com/in/index.html" target="_blank" rel="noopener noreferrer">Triazine Software Pvt. Ltd.</a>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;
