import React, { useState } from "react";

import { makeStyles } from '@mui/styles';
import {
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  RadioGroup,
  Radio,
} from "@mui/material";



const useStyles = makeStyles(() => ({
  errorField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: '#D92455',
      },
      "&:hover fieldset": {
        borderColor: '#D92455',
      },
      "&.Mui-focused fieldset": {
        borderColor: '#D92455',
      },
    },
    "& .MuiFormLabel-root": {
      color: '#D92455',
    },
  },
}));

function RegistrationOnline() {
  const classes = useStyles();
  const [errors, setErrors] = useState({
    dob: false,
    issueDate: false,
    expiryDate: false,
    arrivalDate: false,
    departureDate: false,
  });

  const handleBlur = (field) => (event) => {
    setErrors((prev) => ({
      ...prev,
      [field]: !event.target.value,
    }));
  };

  const tableStyle = {
    borderCollapse: "collapse",
    width: "100%",
  };

  const ulStyle = {
    listStyleType: "none",
    padding: 0,
  };

  const liStyle = {
    marginBottom: "10px",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "#D0F4DE",
        maxWidth: "1000px",
        margin: "auto",
        padding: "50px",
        marginTop: "30px",
      }}
    >
      <Grid
        sx={{
          backgroundColor: "#FFFF",
          borderRadius: "10px",
          padding: "20px",
          maxWidth: "950px",
        }}
      >

<Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: "20px",
              color: "#000000DE",
              fontSize: "34px",
              fontWeigt: "400",
            }}
          >
            <Typography variant="h4" gutterBottom>
              Registration Form
            </Typography>
          </Box>
          <Grid container spacing={10}>
            {/* Left side: Select Country dropdown */}
            <Grid item xs={4.5}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Select Country</InputLabel>
                <Select
                  label="Select Country"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Adjust as needed
                        width: "auto",
                      },
                    },
                  }}
                >
                  <MenuItem value="Afghanistan">Afghanistan</MenuItem>
                  <MenuItem value="Albania.">Albania</MenuItem>
                  <MenuItem value="Algeria">Algeria</MenuItem>
                  <MenuItem value="American Samoa">American Samoa</MenuItem>
                  <MenuItem value="Andorra">Andorra</MenuItem>

                  <MenuItem value="Angola">Angola</MenuItem>
                  <MenuItem value="Anguilla.">Anguilla</MenuItem>
                  <MenuItem value="Antarctica">Antarctica</MenuItem>
                  <MenuItem value="American Samoa">American Samoa</MenuItem>
                  <MenuItem value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </MenuItem>
                  <MenuItem value="Argentina">Argentina</MenuItem>
                  <MenuItem value="Armenia.">Armenia</MenuItem>
                  <MenuItem value="Aruba">Aruba</MenuItem>
                  <MenuItem value="Australia">Australia</MenuItem>
                  <MenuItem value="Austria">Austria</MenuItem>

                  <MenuItem value="Azerbaijan">Azerbaijan</MenuItem>
                  <MenuItem value="Bahamas">Bahamas</MenuItem>
                  <MenuItem value="Bahrain">Bahrain</MenuItem>
                  <MenuItem value="Bangladesh">Bangladesh</MenuItem>
                  <MenuItem value="Barbados">Barbados</MenuItem>

                  <MenuItem value="Belarus">Belarus</MenuItem>
                  <MenuItem value="Belgium">Belgium</MenuItem>
                  <MenuItem value="Belize">Belize</MenuItem>
                  <MenuItem value="Benin">Benin</MenuItem>
                  <MenuItem value="Bermuda">Bermuda</MenuItem>

                  <MenuItem value="Bhutan">Bhutan</MenuItem>
                  <MenuItem value="Bolivia">Bolivia</MenuItem>
                  <MenuItem value="BOSNIA-HERZ.">BOSNIA-HERZ.</MenuItem>
                  <MenuItem value="Botswana">Botswana</MenuItem>
                  <MenuItem value="Bouvet Island">Bouvet Island</MenuItem>

                  <MenuItem value="Brazil">Brazil</MenuItem>
                  <MenuItem value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </MenuItem>
                  <MenuItem value="Brunei Darussalam">
                    Brunei Darussalam
                  </MenuItem>
                  <MenuItem value="Bulgaria">Bulgaria</MenuItem>
                  <MenuItem value="Burkina Faso">Burkina Faso</MenuItem>

                  <MenuItem value="Burundi">Burundi</MenuItem>
                  <MenuItem value="Cambodia">Cambodia</MenuItem>
                  <MenuItem value="Cameroon">Cameroon</MenuItem>
                  <MenuItem value="Canada">Canada</MenuItem>
                  <MenuItem value="Cape Verde">Cape Verde</MenuItem>

                  <MenuItem value="Cayman Islands">Cayman Islands</MenuItem>
                  <MenuItem value="Central African Republic">
                    Central African Republic
                  </MenuItem>
                  <MenuItem value="Chad.">Chad.</MenuItem>
                  <MenuItem value="Chile">Chile</MenuItem>
                  <MenuItem value="China">China</MenuItem>

                  <MenuItem value="Christmas Island">Christmas Island</MenuItem>
                  <MenuItem value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </MenuItem>
                  <MenuItem value="Colombia">Colombia.</MenuItem>
                  <MenuItem value="Comoros">Comoros</MenuItem>
                  <MenuItem value="Congo">Congo</MenuItem>

                  <MenuItem value="Congo, the Democratic Republic of the">
                    Congo, the Democratic Republic of the
                  </MenuItem>
                  <MenuItem value="Cook Islands">Cook Islands</MenuItem>
                  <MenuItem value="Costa Rica">Costa Rica</MenuItem>
                  <MenuItem value="Cote D'Ivoire">Cote D'Ivoire</MenuItem>
                  <MenuItem value="Croatia">Croatia</MenuItem>

                  <MenuItem value="Cuba">Cuba</MenuItem>
                  <MenuItem value="Cyprus">Cyprus</MenuItem>
                  <MenuItem value="Czech Republic">Czech Republic</MenuItem>
                  <MenuItem value="Denmark">Denmark</MenuItem>
                  <MenuItem value="Djibouti">Djibouti</MenuItem>

                  <MenuItem value="Dominica">Dominica</MenuItem>
                  <MenuItem value="Dominican Republic">
                    Dominican Republic
                  </MenuItem>
                  <MenuItem value="Ecuador">Ecuador</MenuItem>
                  <MenuItem value="Egypt">Egypt</MenuItem>
                  <MenuItem value="El Salvador">El Salvador</MenuItem>

                  <MenuItem value="Equatorial Guinea">
                    Equatorial Guinea
                  </MenuItem>
                  <MenuItem value="Eritrea">Eritrea</MenuItem>
                  <MenuItem value="Estonia">Estonia</MenuItem>
                  <MenuItem value="Ethiopia">Ethiopia</MenuItem>
                  <MenuItem value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </MenuItem>

                  <MenuItem value="Faroe Islands">Faroe Islands</MenuItem>
                  <MenuItem value="Fiji">Fiji</MenuItem>
                  <MenuItem value="Finland">Finland.</MenuItem>
                  <MenuItem value="France">France</MenuItem>
                  <MenuItem value="French Guiana">French Guiana</MenuItem>

                  <MenuItem value="French Polynesia">French Polynesia</MenuItem>
                  <MenuItem value="French Southern Territories">
                    French Southern Territories
                  </MenuItem>
                  <MenuItem value="Gabon">Gabon</MenuItem>
                  <MenuItem value="Gambia">Gambia</MenuItem>
                  <MenuItem value="Georgia">Georgia</MenuItem>

                  <MenuItem value="Germany">Germany</MenuItem>
                  <MenuItem value="Ghana">Ghana</MenuItem>
                  <MenuItem value="Gibraltar">Gibraltar</MenuItem>
                  <MenuItem value="Greece">Greece</MenuItem>
                  <MenuItem value="Greenland">Greenland</MenuItem>

                  <MenuItem value="Grenada">Grenada</MenuItem>
                  <MenuItem value="Guadeloupe">Guadeloupe</MenuItem>
                  <MenuItem value="Guam">Guam</MenuItem>
                  <MenuItem value="Guatemala">Guatemala</MenuItem>
                  <MenuItem value="Guinea">Guinea</MenuItem>

                  <MenuItem value="Guinea-Bissau">Guinea-Bissau</MenuItem>
                  <MenuItem value="Guyana">Guyana</MenuItem>
                  <MenuItem value="Haiti">Haiti</MenuItem>
                  <MenuItem value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </MenuItem>
                  <MenuItem value="Holy See (Vatican City State))">
                    Holy See (Vatican City State)
                  </MenuItem>

                  <MenuItem value="Honduras">Honduras</MenuItem>
                  <MenuItem value="Hong Kong">Hong Kong</MenuItem>
                  <MenuItem value="Hungary">Hungary.</MenuItem>
                  <MenuItem value="Iceland">Iceland</MenuItem>
                  <MenuItem value="India">India</MenuItem>

                  <MenuItem value="Indonesia">Indonesia</MenuItem>
                  <MenuItem value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </MenuItem>
                  <MenuItem value="Iraq">Iraq</MenuItem>
                  <MenuItem value="Ireland">Ireland</MenuItem>
                  <MenuItem value="Israel">Israel</MenuItem>

                  <MenuItem value="Italy">Italy</MenuItem>
                  <MenuItem value="Jamaica">Jamaica</MenuItem>
                  <MenuItem value="Japan">Japan</MenuItem>
                  <MenuItem value="Jordan">Jordan</MenuItem>
                  <MenuItem value="Kazakhstan">Kazakhstan</MenuItem>

                  <MenuItem value="Kenya">Kenya</MenuItem>
                  <MenuItem value="Kirghizstan">Kirghizstan</MenuItem>
                  <MenuItem value="Kiribati">Kiribati</MenuItem>
                  <MenuItem value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </MenuItem>
                  <MenuItem value="Korea, Republic of">
                    Korea, Republic of
                  </MenuItem>

                  <MenuItem value="Kuwait">Kuwait</MenuItem>
                  <MenuItem value="LAOS">LAOS</MenuItem>
                  <MenuItem value="Laos">Laos</MenuItem>
                  <MenuItem value="Latvia">Latvia</MenuItem>
                  <MenuItem value="Lebanon">Lebanon</MenuItem>

                  <MenuItem value="Lesotho">Lesotho</MenuItem>
                  <MenuItem value="Liberia">Liberia</MenuItem>
                  <MenuItem value="Libya">Libya.</MenuItem>
                  <MenuItem value="Liechtenstein">Liechtenstein</MenuItem>
                  <MenuItem value="Lithuania">Lithuania</MenuItem>

                  <MenuItem value="Luxembourg">Luxembourg</MenuItem>
                  <MenuItem value="Macao">Macao</MenuItem>
                  <MenuItem value="Madagascar">Madagascar</MenuItem>
                  <MenuItem value="Malawi">Malawi</MenuItem>
                  <MenuItem value="Malaysia">Malaysia</MenuItem>

                  <MenuItem value="Maldives">Maldives</MenuItem>
                  <MenuItem value="Mali">Mali</MenuItem>
                  <MenuItem value="Malta">Malta</MenuItem>
                  <MenuItem value="Marshall Islands">Marshall Islands</MenuItem>
                  <MenuItem value="Martinique">Martinique</MenuItem>

                  <MenuItem value="Mauritania">Mauritania</MenuItem>
                  <MenuItem value="Mauritius">Mauritius</MenuItem>
                  <MenuItem value="Mayotte">Mayotte</MenuItem>
                  <MenuItem value="Mexico">Mexico</MenuItem>
                  <MenuItem value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </MenuItem>

                  <MenuItem value="Moldova, Republic of">
                    Moldova, Republic of
                  </MenuItem>
                  <MenuItem value="Monaco">Monaco</MenuItem>
                  <MenuItem value="Mongolia">Mongolia</MenuItem>
                  <MenuItem value="Montserrat">Montserrat</MenuItem>
                  <MenuItem value="Morocco">Morocco</MenuItem>

                  <MenuItem value="Mozambique">Mozambique</MenuItem>
                  <MenuItem value="Myanmar">Myanmar</MenuItem>
                  <MenuItem value="Namibia">Namibia</MenuItem>
                  <MenuItem value="Nauru">Nauru</MenuItem>
                  <MenuItem value="Nepal">Nepal</MenuItem>

                  <MenuItem value="Netherlands">Netherlands</MenuItem>
                  <MenuItem value="Netherlands Antilles">
                    Netherlands Antilles
                  </MenuItem>
                  <MenuItem value="New Caledonia">New Caledonia</MenuItem>
                  <MenuItem value="New Zealand">New Zealand</MenuItem>
                  <MenuItem value="Nicaragua">Nicaragua</MenuItem>

                  <MenuItem value="Niger">Niger</MenuItem>
                  <MenuItem value="Nigeria">Nigeria</MenuItem>
                  <MenuItem value="Niue">Niue</MenuItem>
                  <MenuItem value="Norfolk Island">Norfolk Island</MenuItem>
                  <MenuItem value="North Macedonia">North Macedonia</MenuItem>

                  <MenuItem value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </MenuItem>
                  <MenuItem value="Norway">Norway</MenuItem>
                  <MenuItem value="Oman">Oman</MenuItem>
                  <MenuItem value="Pakistan">Pakistan</MenuItem>
                  <MenuItem value="Palau">Palau</MenuItem>

                  <MenuItem value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </MenuItem>
                  <MenuItem value="Panama">Panama</MenuItem>
                  <MenuItem value="Papua New Guinea">Papua New Guinea</MenuItem>
                  <MenuItem value="Paraguay">Paraguay</MenuItem>
                  <MenuItem value="Peru">Peru</MenuItem>

                  <MenuItem value="Philippines">Philippines</MenuItem>
                  <MenuItem value="Pitcairn">Pitcairn</MenuItem>
                  <MenuItem value="Poland">Poland</MenuItem>
                  <MenuItem value="Portugal">Portugal</MenuItem>
                  <MenuItem value="Puerto Rico">Puerto Rico</MenuItem>

                  <MenuItem value="Qatar">Qatar</MenuItem>
                  <MenuItem value="Reunion">Reunion</MenuItem>
                  <MenuItem value="Romania">Romania</MenuItem>
                  <MenuItem value="Russian Federation">
                    Russian Federation
                  </MenuItem>
                  <MenuItem value="Rwanda">Rwanda</MenuItem>

                  <MenuItem value="Saint Helena">Saint Helena</MenuItem>
                  <MenuItem value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </MenuItem>
                  <MenuItem value="Saint Lucia">Saint Lucia</MenuItem>
                  <MenuItem value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </MenuItem>
                  <MenuItem value="Saint Vincent and the Grenadines">
                    Saint Vincent and the Grenadines
                  </MenuItem>

                  <MenuItem value="Samoa">Samoa</MenuItem>
                  <MenuItem value="San Marino">San Marino</MenuItem>
                  <MenuItem value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </MenuItem>
                  <MenuItem value="Saudi Arabia">Saudi Arabia</MenuItem>
                  <MenuItem value="Senegal">Senegal</MenuItem>

                  <MenuItem value="Serbia and Montenegro">
                    Serbia and Montenegro
                  </MenuItem>
                  <MenuItem value="Seychelles">Seychelles</MenuItem>
                  <MenuItem value="Sierra Leone">Sierra Leone</MenuItem>
                  <MenuItem value="Singapore">Singapore</MenuItem>
                  <MenuItem value="Slovakia">Slovakia</MenuItem>

                  <MenuItem value="Slovenia">Slovenia</MenuItem>
                  <MenuItem value="Solomon Islands">Solomon Islands</MenuItem>
                  <MenuItem value="Somalia">Somalia</MenuItem>
                  <MenuItem value="South Africa">South Africa</MenuItem>
                  <MenuItem value="South Georgia and the South Sandwich Islands">
                    South Georgia and the South Sandwich Islands
                  </MenuItem>

                  <MenuItem value="Spain">Spain</MenuItem>
                  <MenuItem value="Sri Lanka">Sri Lanka</MenuItem>
                  <MenuItem value="Sudan">Sudan</MenuItem>
                  <MenuItem value="Suriname">Suriname</MenuItem>
                  <MenuItem value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </MenuItem>

                  <MenuItem value="Swaziland">Swaziland</MenuItem>
                  <MenuItem value="Sweden">Sweden</MenuItem>
                  <MenuItem value="Switzerland">Switzerland</MenuItem>
                  <MenuItem value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </MenuItem>
                  <MenuItem value="Taiwan, Province of China">
                    Taiwan, Province of China
                  </MenuItem>

                  <MenuItem value="Tajikistan">Tajikistan</MenuItem>
                  <MenuItem value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </MenuItem>
                  <MenuItem value="Thailand">Thailand</MenuItem>
                  <MenuItem value="Timor-Leste">Timor-Leste</MenuItem>
                  <MenuItem value="Togo">Togo</MenuItem>

                  <MenuItem value="Tokelau">Tokelau</MenuItem>
                  <MenuItem value="Tonga">Tonga</MenuItem>
                  <MenuItem value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </MenuItem>
                  <MenuItem value="Tunisia">Tunisia</MenuItem>
                  <MenuItem value="Turkey">Turkey</MenuItem>

                  <MenuItem value="Turkmenistan">Turkmenistan</MenuItem>
                  <MenuItem value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </MenuItem>
                  <MenuItem value="Tuvalu">Tuvalu</MenuItem>
                  <MenuItem value="Uganda">Uganda</MenuItem>
                  <MenuItem value="Ukraine">Ukraine</MenuItem>

                  <MenuItem value="United Arab Emirates">
                    United Arab Emirates
                  </MenuItem>
                  <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                  <MenuItem value="United States">United States</MenuItem>
                  <MenuItem value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </MenuItem>
                  <MenuItem value="Uruguay">Uruguay</MenuItem>

                  <MenuItem value="Uzbekistan">Uzbekistan</MenuItem>
                  <MenuItem value="Vanuatu">Vanuatu</MenuItem>
                  <MenuItem value="Venezuela">Venezuela</MenuItem>
                  <MenuItem value="Vietnam">Vietnam</MenuItem>
                  <MenuItem value="Virgin Islands, British">
                    Virgin Islands, British
                  </MenuItem>

                  <MenuItem value="Virgin Islands, U.s.">
                    Virgin Islands, U.s.
                  </MenuItem>
                  <MenuItem value="Wallis and Futuna">
                    Wallis and Futuna
                  </MenuItem>
                  <MenuItem value="Western Sahara">Western Sahara</MenuItem>
                  <MenuItem value="Yemen">Yemen</MenuItem>
                  <MenuItem value="Zambia">Zambia</MenuItem>
                  <MenuItem value="Zimbabwe">Zimbabwe</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Right side: Radio buttons vertically aligned */}
            <Grid item xs={7.5}>
              <FormControl component="fieldset" sx={{ mb: 4 }}>
                <RadioGroup>
                  <FormControlLabel
                    value="ICOLD Members"
                    control={<Radio />}
                    label="ICOLD Members"
                  />
                  <FormControlLabel
                    value="SAARC Countries"
                    control={<Radio />}
                    label="SAARC Countries"
                  />
                  <FormControlLabel
                    value="ICOLD Young Delegates"
                    control={<Radio />}
                    label="ICOLD Young Delegates"
                  />
                  <FormControlLabel
                    value="ICOLD Research Students"
                    control={<Radio />}
                    label="ICOLD Research Students"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
         
         
        <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div>
                <table style={tableStyle}>
                  <tbody>
                    <tr>
                      <td style={{
                        border: '1px solid black',
                        padding: '10px',
                        lineHeight: '1.5',
                      }}>
                        <input type="checkbox" />
                        <label> Short course on surveillance and Monitoring for Tailings and Embankment Dams</label>
                      </td>
                      <td style={{
                        border: '1px solid black',
                        padding: '10px',
                        lineHeight: '1.5',
                        verticalAlign: 'top',
                      }}>
                        <input type="checkbox" />
                        <label> Workshop: Design of tailing Dams for closure</label>
                      </td>
                    </tr>
                    <tr>
                      <td style={{
                        border: '1px solid black',
                        padding: '10px',
                        lineHeight: '1.5',
                      }}>
                        <input type="checkbox" />
                        <label> Workshop bulletin 194: Tailings Dams Safety</label>
                      </td>
                      <td style={{
                        border: '1px solid black',
                        padding: '10px',
                        lineHeight: '1.5',
                      }}>
                        Technical Tour:
                        <ul style={ulStyle}>
                          <li style={liStyle}>
                            <input type="checkbox" />
                            <label> (a) 2 days visit to udaipur with tour of TSF at Hindustan zinc zawar zinc refinery (wed 25th/Thursday 26th sept.2024)</label>
                          </li>
                          <li style={liStyle}>
                            <input type="checkbox" />
                            <label> (b) one day visit to jhajjar ash pond</label>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Name*"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Email*"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Organization*"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Designation*"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Address for Correspondence"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Postal Code*"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Date of Birth"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur('dob')}
                    className={errors.dob ? classes.errorField : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Passport No*"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Date of Issue"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur('issueDate')}
                    className={errors.issueDate ? classes.errorField : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Date of Expiry"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur('expiryDate')}
                    className={errors.expiryDate ? classes.errorField : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Place of Issue*"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Date of Arrival"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur('arrivalDate')}
                    className={errors.arrivalDate ? classes.errorField : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="Date of Departure"
                    variant="outlined"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    onBlur={handleBlur('departureDate')}
                    className={errors.departureDate ? classes.errorField : ''}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Button
  variant="contained"
  sx={{
    width: "100%",
    mt: 2,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "default",
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    minWidth: '64px',
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.12)", // Keep the background color same on hover
    },
  }}
>
  Save
</Button>
      </Grid> 
    </Box>
  );
}

export default RegistrationOnline;
