import React from 'react'
// import Home from './pages/Home'
// import President_Invitation from './pages/General_Info/President_Invitation'
// import Secretary_General from './pages/General_Info/Secretary_General'
// import President_Chairman from './pages/General_Info/President_Chairman'
// import Capital_City from './pages/General_Info/Capital_City'
// import About from './pages/General_Info/About'
// import Program_Schedule from './pages/Program/Program_Schedule'
// import ICOLD_Members from './pages/Program/ICOLD_Members'
// import Advisory_Committee from './pages/Program/Advisory_Committee'
// import Organizing_Committee from './pages/Program/Organizing_Committee'
// import Technical_Committee from './pages/Program/Technical_Committee'
// import Symposium_Workshop from './pages/Program/Symposium_Workshop'
// import Sponsors from './pages/Sponsors'
// import Benefits from './pages/Exhibitors/Benefits'
// import Layout from './pages/Exhibitors/Layout'
// import Existing_Exhibitors from './pages/Exhibitors/Existing_Exhibitors'
// import Fee_Details from './pages/Fee_Details'
// import Contacts from './pages/Contacts'
// import Hotels from './pages/Accomodation/Hotels'
// import Pre_Conference from './pages/Tours/Pre_Conference'
// import Post_Conference from './pages/Tours/Post_Conference'
// import Excursion from './pages/Tours/Excursion'
// import Local_Sightseeing from './pages/Tours/Local_Sightseeing'
// import Accompanying_Person from './pages/Tours/Accompanying_Person'
// import Login from './pages/Login'
// import Register from './pages/Register'
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Nevbar from '../src/common/Nevbar';
import './App.css';
import Layout from './Layout/Layout';


function App() {
  return (
    <div>
      <BrowserRouter>
      {/* <Nevbar /> */}
      <Routes>
      <Route
          path="/*"
          element={
            <>
               <Layout/>
            </>
          }
        />
          
        {/* <Route path = '/' element={<Home/>}/>
        <Route path = '/Login' element={<Login/>}/>
        <Route path = '/Register' element={<Register/>}/>
        <Route path = '/president_invitation' element={<President_Invitation/>}/>
        <Route path = '/secretary_general' element={<Secretary_General/>}/>
        <Route path = '/president_chairman' element={<President_Chairman/>}/>
        <Route path = '/capital_city' element={<Capital_City/>}/>
        <Route path = '/about' element={<About/>}/>
        <Route path = '/program_schedule' element={<Program_Schedule/>}/>
        <Route path = '/icold_members' element={<ICOLD_Members/>}/>
        <Route path = '/advisory_committee' element={<Advisory_Committee/>}/>
        <Route path = '/organizing_committee' element={<Organizing_Committee/>}/>
        <Route path = '/technical_committee' element={<Technical_Committee/>}/>
        <Route path = '/symposium_workshop' element={<Symposium_Workshop/>}/>
        <Route path = '/sponsors' element={<Sponsors/>}/>
        <Route path = '/benefits' element={<Benefits/>}/>
        <Route path = '/layout' element={<Layout/>}/>
        <Route path = '/existing_exhibitors' element={<Existing_Exhibitors/>}/>
        <Route path = '/fee_details' element={<Fee_Details/>}/>
        <Route path = '/contacts' element={<Contacts/>}/>
        <Route path = '/hotels' element={<Hotels/>}/>
        <Route path = '/pre_conference' element={<Pre_Conference/>}/>
        <Route path = '/post_conference' element={<Post_Conference/>}/>
        <Route path = '/excursion' element={<Excursion/>}/>
        <Route path = '/local_sightseeing' element={<Local_Sightseeing/>}/>
        <Route path = '/accompanying_person' element={<Accompanying_Person/>}/> */}

      </Routes>

      </BrowserRouter>
    </div>
  )
}

export default App
