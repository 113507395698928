import React from "react";
import titlez from "../../assets/images/register-banner.png";
import {
  Grid,
  Divider,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import ListItemText from "@mui/material/ListItemText";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import Table from "@mui/material/Table";
import closeIcon from "../../assets/images/close-image.png";
const AlertBox = ({ onClose }) => {
  const alertBoxStyle = {
    width: "400px",
    backgroundColor: "#ffffff",
    border: "1px solid #ddd",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "6px",
    fontFamily: "Arial, sans-serif",
    color: "#333",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    maxHeight: "95vh",
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    padding:'20px !important',
    flex:'1 1 auto',
    minWidth:'560px'


  };

  const alertHeaderStyle = {
    backgroundColor: "#3b3b98",
    color: "#ffffff",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  };

  const closeBtnStyle = {
    cursor: "pointer",
  };

  const alertContentStyle = {
    padding: "8px",
  };

  const listStyle = {
    color: "#303030",
    fontSize: "16px",
    margin: "1px",
    fontWeight: "bold",
    
  };
  

  return (
    <>
      <div style={alertBoxStyle}>
        <div style={alertHeaderStyle}>
          <span>
            <h1 style={{ fontSize: "20px" }}>
              Benefits of registering as a Delegate
            </h1>
          </span>
          <button style={closeBtnStyle} onClick={onClose}>
            <CloseSharpIcon />
          </button>
        </div>
        <div style={alertContentStyle}>
          <h5 style={{ fontSize: "20px", color: "#008CFF",lineHeight: "1.5" }}>
            Registration fee Member and Non-member (Full Delegate Registration)
            Includes:
          </h5>
          <ul style={listStyle}>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Admission to the Annual Meeting, ICOLD symposium, APG symposium ,
              INCOLD Workshops and ICOLD Technical Committee Workshops
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Symposium Proceedings (Digital)
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Access to Technical Exhibition from{" "}
              <b>
                29<sup>th</sup>September
              </b>{" "}
              to{" "}
              <b>
                3<sup>rd</sup> October, 2024
              </b>
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Coffee breaks and lunches from 29th September to 3rd October, 2024
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Social programme and city tour
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Delegate bag name badge and conference materials
            </li>
          </ul>
          <h5 style={{ fontSize: "20px", color: "#008CFF",lineHeight: "1.5" }}>
            Registration Fee Symposium/Workshops only Includes:
          </h5>
          <ul style={listStyle}>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Admission to the Symposium/workshops
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Access to Technical Exhibition from
              <b>
                {" "}
                1<sup>st</sup> – 2<sup>nd</sup> October, 2024{" "}
              </b>
              (Registered for Symposium)
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Coffee breaks, lunches, access to technical exhibition and Social
              activities for symposium only from{" "}
              <b>
                1<sup>st</sup> - 2<sup>nd</sup> October, 2024
              </b>
              and for workshops only from{" "}
              <b>
                2<sup>nd</sup> - 3<sup>rd</sup> October, 2024
              </b>
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Delegate bag, name badge and conference materials
            </li>
          </ul>
          <h5 style={{ fontSize: "20px", color: "#008CFF",lineHeight: "1.5" }}>
            Registration fee Young Professional and Research Students:
          </h5>
          <ul style={listStyle}>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              The Young Professional fee applies if you are 35 years of age or
              under as of{" "}
              <b>
                1<sup>st</sup> September, 2024.
              </b>
              To register as Young Professional you must be able to provide
              proof of age upon registration on-site. Includes:
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Admission to the Annual Meeting, ICOLD symposium, APG symposium,
              INCOLD Workshops and ICOLD Technical Committee Workshops
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Admission to the Young Professional Forum meeting
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Symposium Proceedings (Digital)
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Access to Technical Exhibition from,
              <b>
                29<sup>th</sup> September
              </b>{" "}
              to{" "}
              <b>
                2<sup>nd</sup> October, 2024
              </b>
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Coffee breaks and lunches from,{" "}
              <b>
                29<sup>th</sup> September
              </b>{" "}
              to{" "}
              <b>
                3<sup>rd</sup> October, 2024
              </b>
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Social programme and city tour
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Networking event for Young Professionals
            </li>
            <li style={{ marginLeft: "20px", lineHeight: "2" }}>
              Delegate bag
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};


function RegistrationNow() {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedWorkshops, setSelectedWorkshops] = useState([]);
  const [fullDelegate, setFullDelegate] = useState(false);
  const [symposium, setSymposium] = useState(false);
  const [workshop, setWorkshop] = useState(false);
  const [educational, setEducational] = useState(false);
  const [accept, setAccept] = useState(false);

  const handleFullDelegateChange = (event) => {
    setFullDelegate(event.target.checked);
  };

  const handleSymposiumChange = (event) => {
    setSymposium(event.target.checked);
  };

  const handleWorkshopChange = (event) => {
    setWorkshop(event.target.checked);
  };
  const handleEducationalChange = (event) => {
    setEducational(event.target.checked);
  };

  const handleAcceptChange = (event) => {
    setAccept(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    let hasError = false;

    if (!email) {
      setEmailError(true);
      hasError = true;
    } else {
      setEmailError(false);
    }

    if (!password) {
      setPasswordError(true);
      hasError = true;
    } else {
      setPasswordError(false);
    }

    if (!confirmPassword || confirmPassword !== password) {
      setConfirmPasswordError(true);
      hasError = true;
    } else {
      setConfirmPasswordError(false);
    }

    if (!title) {
      setTitleError(true);
      hasError = true;
    } else {
      setTitleError(false);
    }

    if (!firstname) {
      setFirstNameError(true);
      hasError = true;
    } else {
      setFirstNameError(false);
    }

    if (!lastname) {
      setLastNameError(true);
      hasError = true;
    } else {
      setLastNameError(false);
    }

    if (!dob) {
      setDobError(true);
      hasError = true;
    } else {
      setDobError(false);
    }

    if (!address) {
      setAddressError(true);
      hasError = true;
    } else {
      setAddressError(false);
    }

    if (!country) {
      setCountryError(true);
      hasError = true;
    } else {
      setCountryError(false);
    }

    if (!city) {
      setCityError(true);
      hasError = true;
    } else {
      setCityError(false);
    }

    if (!state) {
      setStateError(true);
      hasError = true;
    } else {
      setStateError(false);
    }

    if (!designation) {
      setDesignationError(true);
      hasError = true;
    } else {
      setDesignationError(false);
    }

    if (!organizationName) {
      setOrganizationNameError(true);
      hasError = true;
    } else {
      setOrganizationNameError(false);
    }

    if (!contactNumber) {
      setContactNumberError(true);
      hasError = true;
    } else {
      setContactNumberError(false);
    }

    if (!group) {
      setGroupError(true);
      hasError = true;
    } else {
      setGroupError(false);
    }

    // If any field is empty, return without submitting
    if (hasError) {
      return;
    }

    // Submit logic here (if all fields are filled)
    console.log('Form submitted successfully!');
  };



  const handleGroupChange1 = (event) => {
    setSelectedWorkshops(event.target.value);
  };
  const handleDelete = (valueToDelete) => () => {
    setSelectedWorkshops((current) =>
      current.filter((value) => value !== valueToDelete)
    );
  };
  const [showAlert, setShowAlert] = useState(true);
  const [showInfo, setShowInfo] = useState(false);

  const handleButtonClick = () => {
    setShowInfo(!showInfo);
  };

  useEffect(() => {
    // Automatically show the alert box when the Home component mounts
    setShowAlert(true);
  }, []);
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 999,
  };
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [stateError, setStateError] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false); // Reset error on change
  };

  const handleBlurEmail = () => {
    if (!email) {
      setEmailError(true);
      setStateError(true);
    } else {
      setStateError(false);
    }
  };

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(""); // Reset error on change
  };

  const handleBlurPassword = () => {
    if (!password) {
      setPasswordError("Password is required");
    } else if (password.length < 5) {
      setPasswordError("Password should be of minimum 5 characters");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordError(""); // Reset error on change
  };

  const handleBlurConfirmPassword = () => {
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
    } else if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setTitleError(false); // Reset error on change
  };

  const handleBlurTitle = () => {
    if (!title) {
      setTitleError(true);
    }
  };

  const [firstname, setFirstName] = useState("");
  const [firstnameError, setFirstNameError] = useState(false);
  const [firstnameTouched, setFirstNameTouched] = useState(false);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    if (firstnameTouched) {
      setFirstNameError(e.target.value === "");
    }
  };

  const handleFirstNameBlur = () => {
    setFirstNameTouched(true);
    setFirstNameError(firstname === "");
  };
  const [lastname, setLastName] = useState("");
  const [lastnameError, setLastNameError] = useState(false);
  const [lastnameTouched, setLastNameTouched] = useState(false);

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    if (lastnameTouched) {
      setLastNameError(e.target.value === "");
    }
  };

  const handleLastNameBlur = () => {
    setLastNameTouched(true);
    setLastNameError(lastname === "");
  };
  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState(false);

  const handleDobChange = (event) => {
    setDob(event.target.value);
    setDobError(false); // Reset error on change
  };

  const handleDobBlur = () => {
    if (!dob) {
      setDobError(true);
    } else {
      const regex = /^\d{2}-\d{2}-\d{4}$/;
      if (!regex.test(dob)) {
        setDobError(true);
      }
    }
  };

  const [group, setGroup] = useState("");
  const [country, setCountry] = useState("");
  const [groupError, setGroupError] = useState(false);
  const [countryError, setCountryError] = useState(false);

  const handleGroupChange = (event) => {
    setGroup(event.target.value);
    // Reset group error state when group is selected
    setGroupError(false);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    // Reset country error state when country is selected
    setCountryError(false);
  };

  const handleBlur = () => {
    if (!group) {
      setGroupError(true);
    }
    if (!country) {
      setCountryError(true);
    }
  };

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);
  const [cityTouched, setCityTouched] = useState(false);
  const handleCityChange = (e) => {
    setCity(e.target.value);
    if (cityTouched) {
      setCityError(e.target.value === "");
    }
  };

  const handleBlurCity = () => {
    setCityTouched(true);
    setCityError(city === "");
  };

  const [state, setState] = useState("");
  const [stateTouched, setStateTouched] = useState(false);

  const handleStateChange = (e) => {
    setState(e.target.value);
    if (stateTouched) {
      setStateError(e.target.value === "");
    }
  };

  const handleBlurState = () => {
    setStateTouched(true);
    setStateError(state === "");
  };
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [addressTouched, setAddressTouched] = useState(false);

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    if (addressTouched) {
      setAddressError(e.target.value === "");
    }
  };

  const handleBlurAdd = () => {
    setAddressTouched(true);
    setAddressError(address === "");
  };

  const [designation, setDesignation] = useState("");
  const [designationError, setDesignationError] = useState(false);
  const [designationTouched, setDesignationTouched] = useState(false);

  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
    if (designationTouched) {
      setDesignationError(e.target.value === "");
    }
  };

  const handleBlurDesignation = () => {
    setDesignationTouched(true);
    setDesignationError(designation === "");
  };

  const [organizationName, setOrganizationName] = useState("");
  const [organizationNameError, setOrganizationNameError] = useState(false);
  const [organizationNameTouched, setOrganizationNameTouched] = useState(false);

  const handleOrganizationNameChange = (e) => {
    setOrganizationName(e.target.value);
    if (organizationNameTouched) {
      setOrganizationNameError(e.target.value === "");
    }
  };

  const handleBlurOrganization = () => {
    setOrganizationNameTouched(true);
    setOrganizationNameError(organizationName === "");
  };

  const [contactNumber, setContactNumber] = useState("");
  const [contactNumberError, setContactNumberError] = useState(false);
  const [contactNumberTouched, setContactNumberTouched] = useState(false);

  const handleContactNumberChange = (e) => {
    setContactNumber(e.target.value);
    if (contactNumberTouched) {
      setContactNumberError(e.target.value === "");
    }
  };

  const handleBlurContact = () => {
    setContactNumberTouched(true);
    setContactNumberError(contactNumber === "");
  };

  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({ title: "", body: "" });

  const handleAlertOpen = (content) => {
    setDialogContent(content);
    setOpen(true);
  };

  const handleAlertClose = () => {
    setOpen(false);
  };

  const [opentwo, setOpenTwo] = useState(false);
  const [dialogContenttwo, setDialogContentTwo] = useState({
    title: "",
    body: null,
  });

  const handleAlertOpenTwo = (content) => {
    setDialogContentTwo(content);
    setOpenTwo(true);
  };

  const handleAlertCloseTwo = () => {
    setOpenTwo(false);
  };

  

  const orderDetailsContent = {
    title: "Order Details",
    body: (
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th
              style={{ padding: "8px", fontWeight: "bold", fontSize: "17px" }}
            >
              S No.
            </th>
            <th style={{ padding: "8px", fontSize: "17px", fontSize: "17px" }}>
              Description
            </th>
            <th style={{ padding: "8px", fontSize: "17px", fontSize: "17px" }}>
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th
              style={{
                borderBottom: "1px solid #ddd",
                padding: "8px",
                fontSize: "17px",
              }}
            >
              1
            </th>
            <td
              style={{
                borderBottom: "1px solid #ddd",
                padding: "8px",
                fontSize: "17px",
              }}
            >
              Registration Fee
            </td>
            <td
              style={{
                borderBottom: "1px solid  #ddd",
                padding: "8px",
                fontSize: "17px",
              }}
            >
              USD 0
            </td>
          </tr>
          <tr>
            <th
              style={{
                borderBottom: "1px solid #ddd",
                padding: "8px",
                fontSize: "17px",
              }}
            >
              2
            </th>
            <td
              style={{
                borderBottom: "1px solid #ddd",
                padding: "8px",
                fontSize: "17px",
              }}
            >
              Food
            </td>
            <td
              style={{
                borderBottom: "1px solid #ddd",
                padding: "8px",
                fontSize: "17px",
              }}
            >
              USD 0
            </td>
          </tr>
          <tr>
            <th
              style={{
                borderBottom: "1px solid #ddd",
                padding: "8px",
                fontSize: "17px",
              }}
            >
              3
            </th>
            <td
              style={{
                borderBottom: "1px solid #ddd",
                padding: "8px",
                fontSize: "17px",
              }}
            >
              GST 18%
            </td>
            <td
              style={{
                borderBottom: "1px solid #ddd",
                padding: "8px",
                fontSize: "17px",
              }}
            >
              USD 0
            </td>
          </tr>
          <tr>
            <th
              colSpan="2"
              style={{
                borderTop: "1px solid #ddd",
                padding: "8px",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              Grand Total
            </th>
            <td
              style={{
                borderTop: "1px solid #ddd",
                padding: "8px",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              USD 0
            </td>
          </tr>
        </tbody>
      </table>
    ),
  };



  const [selectedFunctions, setSelectedFunctions] = useState([]);

  const handleFunctionChange = (event) => {
    setSelectedFunctions(event.target.value);
  };

 

  const functions = [
    "Welcome Reception",
    "Cultural Evening",
    "Farewell Dinner"
  ];
  
  return (
    <>
      {showAlert && <div style={overlayStyle}></div>}
      {showAlert && <AlertBox onClose={() => setShowAlert(false)} />}
      <div style={{ filter: showAlert ? "none" : "none" }}>
        {" "}
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} style={{ position: "relative" }}>
            <img
              src={titlez}
              alt="..."
              style={{ width: "100%", height: "200px", objectFit: "fill" }}
            />
            <div
              style={{
                position: "absolute",
                top: "20px",
                left: "30px",
                color: "white",
                fontSize: "16px",
                fontFamily: "Epilogue, sans-serif",
                lineHeight: "26.4px",
                fontWeight: "500",
                marginBottom: "2%",
              }}
            >
              <b>Home</b> &gt; <b>Registration Now</b> &gt;{" "}
              <b>Delegate Registration</b>
            </div>
            <div
              style={{
                position: "absolute",
                top: "55%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                color: "white",
                width: "80%",
                fontSize: "41px",
                fontFamily: "Epilogue, sans-serif !important",
                lineHeight: "55px",
                fontWeight: "900",
              }}
            >
              <b>Delegate Registration</b>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} ml={3} mb={2}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "left",
              color: "#3e4095",
              fontSize: "22px",
              marginBottom: "1%",
            }}
          >
            Details used for login
          </Grid>
          <Divider
            style={{
              marginTop: "1%",
              marginBottom: "1%",
              backgroundColor: "#3e4095",
            }}
          />

          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Grid
              container
              spacing={2}
              style={{ marginLeft: "1%", marginBottom: "1%" }}
            >
              <Grid item xs={3.8}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Email <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  placeholder="Enter Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
            onBlur={() => setEmailError(!email)}
            error={emailError}
            helperText={emailError ? 'Email is required' : ''}
                />
              </Grid>

              <Grid item xs={3.8}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Password <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
            fullWidth
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={() => setPasswordError(!password)}
            error={passwordError}
            helperText={passwordError ? 'Password is required' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3.8} mb={1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Confirm Password <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
            fullWidth
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            placeholder="Confirm Password"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={() => setConfirmPasswordError(confirmPassword !== password)}
            error={confirmPasswordError}
            helperText={confirmPasswordError ? 'Passwords do not match' : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                textAlign: "left",
                color: "#3e4095",
                fontSize: "22px",
                marginBottom: "1%",
                marginLeft: "1%",
              }}
            >
              Profile details
            </Grid>
            <Divider style={{ marginTop: "1%", marginBottom: "1%" }} />
            <Grid
              container
              spacing={2}
              style={{ marginLeft: "1%", marginBottom: "1%" }}
            >
              <Grid item xs={2}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Title<sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <FormControl
                  fullWidth
                  sx={{ mb: 2 }}
                  variant="outlined"
                  error={titleError}
                >
                  <InputLabel id="title-label">Title</InputLabel>
                  <Select
                    labelId="title-label"
                    value={title}
                    onChange={handleTitleChange}
                    onBlur={handleBlurTitle}
                    label="Title"
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value="Mr.">Mr.</MenuItem>
                    <MenuItem value="Mrs.">Mrs.</MenuItem>
                    <MenuItem value="Miss">Miss</MenuItem>
                    <MenuItem value="Dr.">Dr.</MenuItem>
                    <MenuItem value="Professor">Professor</MenuItem>
                  </Select>
                  {titleError && (
                    <p style={{ color: "#D92445", margin: "3px 0 0" }}>
                      Title is required
                    </p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={3.1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  First Name <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="Enter First Name"
                  required
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  onBlur={() => setFirstNameError(!firstname)}
                  error={firstnameError}
                  helperText={firstnameError ? "First Name is required" : ""}
                />
              </Grid>
              <Grid item xs={3.1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Middle Name{" "}
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter Middle Name"
                  required
                />
              </Grid>
              <Grid item xs={3.1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Last Name <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="Enter your Last Name"
                  required
                  value={lastname}
                  onChange={handleLastNameChange}
                  onBlur={handleLastNameBlur}
                  error={lastnameError}
                  helperText={lastnameError ? "Last Name is required" : ""}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ marginLeft: "1%", marginBottom: "1%" }}
            >
              <Grid item xs={3.1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Date Of Birth <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="dd-mm-yyyy"
                  required
                  value={dob}
                  onChange={handleDobChange}
                  onBlur={handleDobBlur}
                  error={dobError}
                  helperText={
                    dobError
                      ? "Date Of Birth is required and should be in dd-mm-yyyy format"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={3.1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Postal Code <sup style={{ color: "#D92445" }}></sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="Enter Postal Code"
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Group <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
                  <InputLabel
                    id="group-label"
                    style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.875rem",
                    }}
                  >
                    Select Group
                  </InputLabel>
                  <Select
                    labelId="group-label"
                    value={group}
                    onChange={handleGroupChange}
                    onBlur={handleBlur}
                    error={groupError}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>Select Group</em>
                    </MenuItem>
                    <MenuItem value="All Countries">All Countries</MenuItem>
                    <MenuItem value="SAARC Countries">SAARC Countries</MenuItem>
                    <MenuItem value="Member Countries">
                      Member Countries
                    </MenuItem>
                    <MenuItem value="Non-member Countries">
                      Non-member Countries
                    </MenuItem>
                  </Select>
                  {groupError && (
                    <FormHelperText error>Group is required</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={3.1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Country/Region <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
                  <InputLabel
                    id="country-label"
                    style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.875rem",
                    }}
                  >
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="country-label"
                    value={country}
                    onChange={handleCountryChange}
                    onBlur={handleBlur}
                    error={countryError}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value="Afghanistan">Afghanistan</MenuItem>
                    <MenuItem value="Albania.">Albania</MenuItem>
                    <MenuItem value="Algeria">Algeria</MenuItem>
                    <MenuItem value="American Samoa">American Samoa</MenuItem>
                    <MenuItem value="Andorra">Andorra</MenuItem>

                    <MenuItem value="Angola">Angola</MenuItem>
                    <MenuItem value="Anguilla.">Anguilla</MenuItem>
                    <MenuItem value="Antarctica">Antarctica</MenuItem>
                    <MenuItem value="American Samoa">American Samoa</MenuItem>
                    <MenuItem value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </MenuItem>
                    <MenuItem value="Argentina">Argentina</MenuItem>
                    <MenuItem value="Armenia.">Armenia</MenuItem>
                    <MenuItem value="Aruba">Aruba</MenuItem>
                    <MenuItem value="Australia">Australia</MenuItem>
                    <MenuItem value="Austria">Austria</MenuItem>

                    <MenuItem value="Azerbaijan">Azerbaijan</MenuItem>
                    <MenuItem value="Bahamas">Bahamas</MenuItem>
                    <MenuItem value="Bahrain">Bahrain</MenuItem>
                    <MenuItem value="Bangladesh">Bangladesh</MenuItem>
                    <MenuItem value="Barbados">Barbados</MenuItem>

                    <MenuItem value="Belarus">Belarus</MenuItem>
                    <MenuItem value="Belgium">Belgium</MenuItem>
                    <MenuItem value="Belize">Belize</MenuItem>
                    <MenuItem value="Benin">Benin</MenuItem>
                    <MenuItem value="Bermuda">Bermuda</MenuItem>

                    <MenuItem value="Bhutan">Bhutan</MenuItem>
                    <MenuItem value="Bolivia">Bolivia</MenuItem>
                    <MenuItem value="BOSNIA-HERZ.">BOSNIA-HERZ.</MenuItem>
                    <MenuItem value="Botswana">Botswana</MenuItem>
                    <MenuItem value="Bouvet Island">Bouvet Island</MenuItem>

                    <MenuItem value="Brazil">Brazil</MenuItem>
                    <MenuItem value="British Indian Ocean Territory">
                      British Indian Ocean Territory
                    </MenuItem>
                    <MenuItem value="Brunei Darussalam">
                      Brunei Darussalam
                    </MenuItem>
                    <MenuItem value="Bulgaria">Bulgaria</MenuItem>
                    <MenuItem value="Burkina Faso">Burkina Faso</MenuItem>

                    <MenuItem value="Burundi">Burundi</MenuItem>
                    <MenuItem value="Cambodia">Cambodia</MenuItem>
                    <MenuItem value="Cameroon">Cameroon</MenuItem>
                    <MenuItem value="Canada">Canada</MenuItem>
                    <MenuItem value="Cape Verde">Cape Verde</MenuItem>

                    <MenuItem value="Cayman Islands">Cayman Islands</MenuItem>
                    <MenuItem value="Central African Republic">
                      Central African Republic
                    </MenuItem>
                    <MenuItem value="Chad.">Chad.</MenuItem>
                    <MenuItem value="Chile">Chile</MenuItem>
                    <MenuItem value="China">China</MenuItem>

                    <MenuItem value="Christmas Island">
                      Christmas Island
                    </MenuItem>
                    <MenuItem value="Cocos (Keeling) Islands">
                      Cocos (Keeling) Islands
                    </MenuItem>
                    <MenuItem value="Colombia">Colombia.</MenuItem>
                    <MenuItem value="Comoros">Comoros</MenuItem>
                    <MenuItem value="Congo">Congo</MenuItem>

                    <MenuItem value="Congo, the Democratic Republic of the">
                      Congo, the Democratic Republic of the
                    </MenuItem>
                    <MenuItem value="Cook Islands">Cook Islands</MenuItem>
                    <MenuItem value="Costa Rica">Costa Rica</MenuItem>
                    <MenuItem value="Cote D'Ivoire">Cote D'Ivoire</MenuItem>
                    <MenuItem value="Croatia">Croatia</MenuItem>

                    <MenuItem value="Cuba">Cuba</MenuItem>
                    <MenuItem value="Cyprus">Cyprus</MenuItem>
                    <MenuItem value="Czech Republic">Czech Republic</MenuItem>
                    <MenuItem value="Denmark">Denmark</MenuItem>
                    <MenuItem value="Djibouti">Djibouti</MenuItem>

                    <MenuItem value="Dominica">Dominica</MenuItem>
                    <MenuItem value="Dominican Republic">
                      Dominican Republic
                    </MenuItem>
                    <MenuItem value="Ecuador">Ecuador</MenuItem>
                    <MenuItem value="Egypt">Egypt</MenuItem>
                    <MenuItem value="El Salvador">El Salvador</MenuItem>

                    <MenuItem value="Equatorial Guinea">
                      Equatorial Guinea
                    </MenuItem>
                    <MenuItem value="Eritrea">Eritrea</MenuItem>
                    <MenuItem value="Estonia">Estonia</MenuItem>
                    <MenuItem value="Ethiopia">Ethiopia</MenuItem>
                    <MenuItem value="Falkland Islands (Malvinas)">
                      Falkland Islands (Malvinas)
                    </MenuItem>

                    <MenuItem value="Faroe Islands">Faroe Islands</MenuItem>
                    <MenuItem value="Fiji">Fiji</MenuItem>
                    <MenuItem value="Finland">Finland.</MenuItem>
                    <MenuItem value="France">France</MenuItem>
                    <MenuItem value="French Guiana">French Guiana</MenuItem>

                    <MenuItem value="French Polynesia">
                      French Polynesia
                    </MenuItem>
                    <MenuItem value="French Southern Territories">
                      French Southern Territories
                    </MenuItem>
                    <MenuItem value="Gabon">Gabon</MenuItem>
                    <MenuItem value="Gambia">Gambia</MenuItem>
                    <MenuItem value="Georgia">Georgia</MenuItem>

                    <MenuItem value="Germany">Germany</MenuItem>
                    <MenuItem value="Ghana">Ghana</MenuItem>
                    <MenuItem value="Gibraltar">Gibraltar</MenuItem>
                    <MenuItem value="Greece">Greece</MenuItem>
                    <MenuItem value="Greenland">Greenland</MenuItem>

                    <MenuItem value="Grenada">Grenada</MenuItem>
                    <MenuItem value="Guadeloupe">Guadeloupe</MenuItem>
                    <MenuItem value="Guam">Guam</MenuItem>
                    <MenuItem value="Guatemala">Guatemala</MenuItem>
                    <MenuItem value="Guinea">Guinea</MenuItem>

                    <MenuItem value="Guinea-Bissau">Guinea-Bissau</MenuItem>
                    <MenuItem value="Guyana">Guyana</MenuItem>
                    <MenuItem value="Haiti">Haiti</MenuItem>
                    <MenuItem value="Heard Island and Mcdonald Islands">
                      Heard Island and Mcdonald Islands
                    </MenuItem>
                    <MenuItem value="Holy See (Vatican City State))">
                      Holy See (Vatican City State)
                    </MenuItem>

                    <MenuItem value="Honduras">Honduras</MenuItem>
                    <MenuItem value="Hong Kong">Hong Kong</MenuItem>
                    <MenuItem value="Hungary">Hungary.</MenuItem>
                    <MenuItem value="Iceland">Iceland</MenuItem>
                    <MenuItem value="India">India</MenuItem>

                    <MenuItem value="Indonesia">Indonesia</MenuItem>
                    <MenuItem value="Iran, Islamic Republic of">
                      Iran, Islamic Republic of
                    </MenuItem>
                    <MenuItem value="Iraq">Iraq</MenuItem>
                    <MenuItem value="Ireland">Ireland</MenuItem>
                    <MenuItem value="Israel">Israel</MenuItem>

                    <MenuItem value="Italy">Italy</MenuItem>
                    <MenuItem value="Jamaica">Jamaica</MenuItem>
                    <MenuItem value="Japan">Japan</MenuItem>
                    <MenuItem value="Jordan">Jordan</MenuItem>
                    <MenuItem value="Kazakhstan">Kazakhstan</MenuItem>

                    <MenuItem value="Kenya">Kenya</MenuItem>
                    <MenuItem value="Kirghizstan">Kirghizstan</MenuItem>
                    <MenuItem value="Kiribati">Kiribati</MenuItem>
                    <MenuItem value="Korea, Democratic People's Republic of">
                      Korea, Democratic People's Republic of
                    </MenuItem>
                    <MenuItem value="Korea, Republic of">
                      Korea, Republic of
                    </MenuItem>

                    <MenuItem value="Kuwait">Kuwait</MenuItem>
                    <MenuItem value="LAOS">LAOS</MenuItem>
                    <MenuItem value="Laos">Laos</MenuItem>
                    <MenuItem value="Latvia">Latvia</MenuItem>
                    <MenuItem value="Lebanon">Lebanon</MenuItem>

                    <MenuItem value="Lesotho">Lesotho</MenuItem>
                    <MenuItem value="Liberia">Liberia</MenuItem>
                    <MenuItem value="Libya">Libya.</MenuItem>
                    <MenuItem value="Liechtenstein">Liechtenstein</MenuItem>
                    <MenuItem value="Lithuania">Lithuania</MenuItem>

                    <MenuItem value="Luxembourg">Luxembourg</MenuItem>
                    <MenuItem value="Macao">Macao</MenuItem>
                    <MenuItem value="Madagascar">Madagascar</MenuItem>
                    <MenuItem value="Malawi">Malawi</MenuItem>
                    <MenuItem value="Malaysia">Malaysia</MenuItem>

                    <MenuItem value="Maldives">Maldives</MenuItem>
                    <MenuItem value="Mali">Mali</MenuItem>
                    <MenuItem value="Malta">Malta</MenuItem>
                    <MenuItem value="Marshall Islands">
                      Marshall Islands
                    </MenuItem>
                    <MenuItem value="Martinique">Martinique</MenuItem>

                    <MenuItem value="Mauritania">Mauritania</MenuItem>
                    <MenuItem value="Mauritius">Mauritius</MenuItem>
                    <MenuItem value="Mayotte">Mayotte</MenuItem>
                    <MenuItem value="Mexico">Mexico</MenuItem>
                    <MenuItem value="Micronesia, Federated States of">
                      Micronesia, Federated States of
                    </MenuItem>

                    <MenuItem value="Moldova, Republic of">
                      Moldova, Republic of
                    </MenuItem>
                    <MenuItem value="Monaco">Monaco</MenuItem>
                    <MenuItem value="Mongolia">Mongolia</MenuItem>
                    <MenuItem value="Montserrat">Montserrat</MenuItem>
                    <MenuItem value="Morocco">Morocco</MenuItem>

                    <MenuItem value="Mozambique">Mozambique</MenuItem>
                    <MenuItem value="Myanmar">Myanmar</MenuItem>
                    <MenuItem value="Namibia">Namibia</MenuItem>
                    <MenuItem value="Nauru">Nauru</MenuItem>
                    <MenuItem value="Nepal">Nepal</MenuItem>

                    <MenuItem value="Netherlands">Netherlands</MenuItem>
                    <MenuItem value="Netherlands Antilles">
                      Netherlands Antilles
                    </MenuItem>
                    <MenuItem value="New Caledonia">New Caledonia</MenuItem>
                    <MenuItem value="New Zealand">New Zealand</MenuItem>
                    <MenuItem value="Nicaragua">Nicaragua</MenuItem>

                    <MenuItem value="Niger">Niger</MenuItem>
                    <MenuItem value="Nigeria">Nigeria</MenuItem>
                    <MenuItem value="Niue">Niue</MenuItem>
                    <MenuItem value="Norfolk Island">Norfolk Island</MenuItem>
                    <MenuItem value="North Macedonia">North Macedonia</MenuItem>

                    <MenuItem value="Northern Mariana Islands">
                      Northern Mariana Islands
                    </MenuItem>
                    <MenuItem value="Norway">Norway</MenuItem>
                    <MenuItem value="Oman">Oman</MenuItem>
                    <MenuItem value="Pakistan">Pakistan</MenuItem>
                    <MenuItem value="Palau">Palau</MenuItem>

                    <MenuItem value="Palestinian Territory, Occupied">
                      Palestinian Territory, Occupied
                    </MenuItem>
                    <MenuItem value="Panama">Panama</MenuItem>
                    <MenuItem value="Papua New Guinea">
                      Papua New Guinea
                    </MenuItem>
                    <MenuItem value="Paraguay">Paraguay</MenuItem>
                    <MenuItem value="Peru">Peru</MenuItem>

                    <MenuItem value="Philippines">Philippines</MenuItem>
                    <MenuItem value="Pitcairn">Pitcairn</MenuItem>
                    <MenuItem value="Poland">Poland</MenuItem>
                    <MenuItem value="Portugal">Portugal</MenuItem>
                    <MenuItem value="Puerto Rico">Puerto Rico</MenuItem>

                    <MenuItem value="Qatar">Qatar</MenuItem>
                    <MenuItem value="Reunion">Reunion</MenuItem>
                    <MenuItem value="Romania">Romania</MenuItem>
                    <MenuItem value="Russian Federation">
                      Russian Federation
                    </MenuItem>
                    <MenuItem value="Rwanda">Rwanda</MenuItem>

                    <MenuItem value="Saint Helena">Saint Helena</MenuItem>
                    <MenuItem value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </MenuItem>
                    <MenuItem value="Saint Lucia">Saint Lucia</MenuItem>
                    <MenuItem value="Saint Pierre and Miquelon">
                      Saint Pierre and Miquelon
                    </MenuItem>
                    <MenuItem value="Saint Vincent and the Grenadines">
                      Saint Vincent and the Grenadines
                    </MenuItem>

                    <MenuItem value="Samoa">Samoa</MenuItem>
                    <MenuItem value="San Marino">San Marino</MenuItem>
                    <MenuItem value="Sao Tome and Principe">
                      Sao Tome and Principe
                    </MenuItem>
                    <MenuItem value="Saudi Arabia">Saudi Arabia</MenuItem>
                    <MenuItem value="Senegal">Senegal</MenuItem>

                    <MenuItem value="Serbia and Montenegro">
                      Serbia and Montenegro
                    </MenuItem>
                    <MenuItem value="Seychelles">Seychelles</MenuItem>
                    <MenuItem value="Sierra Leone">Sierra Leone</MenuItem>
                    <MenuItem value="Singapore">Singapore</MenuItem>
                    <MenuItem value="Slovakia">Slovakia</MenuItem>

                    <MenuItem value="Slovenia">Slovenia</MenuItem>
                    <MenuItem value="Solomon Islands">Solomon Islands</MenuItem>
                    <MenuItem value="Somalia">Somalia</MenuItem>
                    <MenuItem value="South Africa">South Africa</MenuItem>
                    <MenuItem value="South Georgia and the South Sandwich Islands">
                      South Georgia and the South Sandwich Islands
                    </MenuItem>

                    <MenuItem value="Spain">Spain</MenuItem>
                    <MenuItem value="Sri Lanka">Sri Lanka</MenuItem>
                    <MenuItem value="Sudan">Sudan</MenuItem>
                    <MenuItem value="Suriname">Suriname</MenuItem>
                    <MenuItem value="Svalbard and Jan Mayen">
                      Svalbard and Jan Mayen
                    </MenuItem>

                    <MenuItem value="Swaziland">Swaziland</MenuItem>
                    <MenuItem value="Sweden">Sweden</MenuItem>
                    <MenuItem value="Switzerland">Switzerland</MenuItem>
                    <MenuItem value="Syrian Arab Republic">
                      Syrian Arab Republic
                    </MenuItem>
                    <MenuItem value="Taiwan, Province of China">
                      Taiwan, Province of China
                    </MenuItem>

                    <MenuItem value="Tajikistan">Tajikistan</MenuItem>
                    <MenuItem value="Tanzania, United Republic of">
                      Tanzania, United Republic of
                    </MenuItem>
                    <MenuItem value="Thailand">Thailand</MenuItem>
                    <MenuItem value="Timor-Leste">Timor-Leste</MenuItem>
                    <MenuItem value="Togo">Togo</MenuItem>

                    <MenuItem value="Tokelau">Tokelau</MenuItem>
                    <MenuItem value="Tonga">Tonga</MenuItem>
                    <MenuItem value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </MenuItem>
                    <MenuItem value="Tunisia">Tunisia</MenuItem>
                    <MenuItem value="Turkey">Turkey</MenuItem>

                    <MenuItem value="Turkmenistan">Turkmenistan</MenuItem>
                    <MenuItem value="Turks and Caicos Islands">
                      Turks and Caicos Islands
                    </MenuItem>
                    <MenuItem value="Tuvalu">Tuvalu</MenuItem>
                    <MenuItem value="Uganda">Uganda</MenuItem>
                    <MenuItem value="Ukraine">Ukraine</MenuItem>

                    <MenuItem value="United Arab Emirates">
                      United Arab Emirates
                    </MenuItem>
                    <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                    <MenuItem value="United States">United States</MenuItem>
                    <MenuItem value="United States Minor Outlying Islands">
                      United States Minor Outlying Islands
                    </MenuItem>
                    <MenuItem value="Uruguay">Uruguay</MenuItem>

                    <MenuItem value="Uzbekistan">Uzbekistan</MenuItem>
                    <MenuItem value="Vanuatu">Vanuatu</MenuItem>
                    <MenuItem value="Venezuela">Venezuela</MenuItem>
                    <MenuItem value="Vietnam">Vietnam</MenuItem>
                    <MenuItem value="Virgin Islands, British">
                      Virgin Islands, British
                    </MenuItem>

                    <MenuItem value="Virgin Islands, U.s.">
                      Virgin Islands, U.s.
                    </MenuItem>
                    <MenuItem value="Wallis and Futuna">
                      Wallis and Futuna
                    </MenuItem>
                    <MenuItem value="Western Sahara">Western Sahara</MenuItem>
                    <MenuItem value="Yemen">Yemen</MenuItem>
                    <MenuItem value="Zambia">Zambia</MenuItem>
                    <MenuItem value="Zimbabwe">Zimbabwe</MenuItem>
                  </Select>
                  {countryError && (
                    <FormHelperText error>Country is required</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fullDelegate}
                      onChange={handleFullDelegateChange}
                      name="fullDelegate"
                      color="primary"
                    />
                  }
                  label="Full Delegate"
                />
              </Grid>
              <Grid item xs={3}>
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={symposium}
                      onChange={handleSymposiumChange}
                      name="symposium"
                      color="primary"
                    />
                  }
                  label="Symposium"
                />
              </Grid>
              <Grid item xs={3}>
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={workshop}
                      onChange={handleWorkshopChange}
                      name="workshop"
                      color="primary"
                    />
                  }
                  label="Workshop"
                />
              </Grid>
              <Grid item xs={3}>
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={educational}
                      onChange={handleEducationalChange}
                      name="educational"
                      color="primary"
                    />
                  }
                  label={
                    <>
                      Educational Institutions <br />
                      (Including Symposium and Workshop)
                    </>
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ marginLeft: "1%", marginBottom: "1%" }}
            >
              <Grid item xs={3.8}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  City <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="city"
                  placeholder="Enter your city"
                  required
                  value={city}
                  onChange={handleCityChange}
                  onBlur={handleBlurCity}
                  error={cityError}
                  helperText={cityError ? "City is required" : ""}
                />
              </Grid>
              <Grid item xs={3.8}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  State <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text" // Set to 'text' for regular text input
                  placeholder="Enter State"
                  required
                  value={state}
                  onChange={handleStateChange}
                  onBlur={handleBlurState}
                  error={stateError}
                  helperText={stateError ? "State is required" : ""}
                />
              </Grid>
              <Grid item xs={3.8} mb={1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Address <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text" // Set to 'text' for regular text input
                  placeholder="Enter Address"
                  required
                  value={address}
                  onChange={handleAddressChange}
                  onBlur={handleBlurAdd}
                  error={addressError}
                  helperText={addressError ? "Address is required" : ""}
                />
              </Grid>

              <Grid item xs={3.8}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Date Of Arrival{" "}
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="firstName"
                  placeholder="dd-mm-yyyy"
                  required
                />
              </Grid>

              <Grid item xs={3.8}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Date Of Departure{" "}
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="firstName"
                  placeholder="dd-mm-yyyy"
                  required
                />
              </Grid>
              <Grid item xs={3.8} mb={1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Passport No{" "}
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="Enter Passport No"
                  required
                />
              </Grid>

              <Grid item xs={3.8} mb={1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Place of Issue{" "}
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="Enter Place of Issue"
                  required
                />
              </Grid>
              <Grid item xs={3.8}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Date of Issue{" "}
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="firstName"
                  placeholder="dd-mm-yyyy"
                  required
                />
              </Grid>
              <Grid item xs={3.8}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Date of Expiry{" "}
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="firstName"
                  placeholder="dd-mm-yyyy"
                  required
                />
              </Grid>

              <Grid item xs={3.8} mb={1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Designation / Title <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text" // Set to 'text' for regular text input
                  placeholder="Enter Designation / Title"
                  required
                  value={designation}
                  onChange={handleDesignationChange}
                  onBlur={handleBlurDesignation}
                  error={designationError}
                  helperText={
                    designationError ? "Designation / Title is required" : ""
                  }
                />
              </Grid>
              <Grid item xs={3.8} mb={1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Organization Name <sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text" // Set to 'text' for regular text input
                  placeholder="Enter Organization Name"
                  required
                  value={organizationName}
                  onChange={handleOrganizationNameChange}
                  onBlur={handleBlurOrganization}
                  error={organizationNameError}
                  helperText={
                    organizationNameError ? "Organization Name is required" : ""
                  }
                />
              </Grid>
              <Grid item xs={3.8} mb={1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Country Code
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  placeholder="Enter Country Code"
                  required
                />
              </Grid>
              <Grid item xs={3.8} mb={1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  Contact Number<sup style={{ color: "#D92445" }}>*</sup>
                </div>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="tel" // Set to 'tel' for telephone number input
                  placeholder="Enter Contact Number"
                  required
                  value={contactNumber}
                  onChange={handleContactNumberChange}
                  onBlur={handleBlurContact}
                  error={contactNumberError}
                  helperText={
                    contactNumberError ? "Contact Number is required" : ""
                  }
                />
              </Grid>

              <Grid item xs={3.8} mb={1}>
                <div style={{ fontSize: "20px", marginBottom: "1%" }}>
                  View Events<sup style={{ color: "#D92445" }}></sup>
                </div>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    variant="outlined"
                    style={{
                      color: "#3e4095",
                      backgroundColor: "transparent",
                      border: "1px solid #3e4095",
                      fontFamily: "Epilogue, sans-serif",
                      padding: "16px 20px",
                      cursor: "pointer",
                      marginRight: "10px",
                      fontSize: "18px",
                      height: "auto",
                      width: "auto",
                      minWidth: "100px",
                      minHeight: "50px",
                      fontWeight: "700",
                      lineHeight: "1",
                      borderRadius: "6px",
                      "&:hover": {
                        backgroundColor: "#D92445",
                        borderColor: "#D92445",
                      },
                    }}
                    onClick={() =>
                      handleAlertOpen({
                        title: "Events",
                        body: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Table>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      paddingRight: "50px",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Sr. No.
                                  </th>
                                  <th
                                    style={{
                                      paddingRight: "50px",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Event Title
                                  </th>
                                </tr>
                              </thead>
                            </Table>
                          </div>
                        ),
                      })
                    }
                  >
                    <b>Events</b>
                  </Button>

                  <Dialog
                    open={open}
                    onClose={handleAlertClose}
                    PaperProps={{
                      style: {
                        top: "-30%", // Position at the top of the screen

                        maxWidth: "100%",
                        width: "30%", // Adjust the max width as per your requirement
                      },
                    }}
                  >
                    <DialogTitle
                      style={{ backgroundColor: "#3e4095", color: "white" }}
                    >
                      {dialogContent.title}
                      <IconButton
                        aria-label="close"
                        onClick={handleAlertClose}
                        style={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: "white",
                        }}
                      >
                        <img
                          src={closeIcon}
                          alt="close"
                          style={{ width: "24px", height: "24px" }}
                        />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Typography gutterBottom>{dialogContent.body}</Typography>
                    </DialogContent>
                  </Dialog>
                </Grid>
              </Grid>
              <Grid item xs={3.8} mb={1}>
              <div style={{ fontSize: "20px", marginBottom: "1%" }}>
              Social Function
                </div>
              <FormControl fullWidth variant="outlined">
      <InputLabel id="social-function-label">Select functions to attend</InputLabel>
      <Select
        labelId="social-function-label"
        value={selectedFunctions}
        onChange={handleFunctionChange}
        label="Select functions to attend"
        multiple
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                deleteIcon={
                  <IconButton
                    onMouseDown={(event) => event.stopPropagation()}
                    onClick={handleDelete(value)}
                  >
                    <CancelIcon />
                  </IconButton>
                }
                onDelete={handleDelete(value)}
              />
            ))}
          </Box>
        )}
      >
        <MenuItem value="Select All">
          <Checkbox
            checked={selectedFunctions.length === functions.length}
            indeterminate={selectedFunctions.length > 0 && selectedFunctions.length < functions.length}
          />
          <ListItemText primary="Select All" />
        </MenuItem>
        {functions.map((func) => (
          <MenuItem key={func} value={func}>
            <Checkbox checked={selectedFunctions.indexOf(func) > -1} />
            <ListItemText primary={func} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ marginLeft: "1%", marginBottom: "1%" }}
            >
              
              <Grid item xs={3.8} mb={1}>
    <div style={{ fontSize: "20px", marginBottom: "1%" }}>Select Food Type</div>
    <FormControl fullWidth variant="outlined">
      <Select
        value={selectedWorkshops}
        onChange={handleGroupChange1}
        multiple
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Select Food Type</em>;
          }

          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  deleteIcon={
                    <IconButton
                      onMouseDown={(event) => event.stopPropagation()}
                      onClick={() => handleDelete(value)}
                    >
                      <CancelIcon />
                    </IconButton>
                  }
                  onDelete={() => handleDelete(value)}
                />
              ))}
            </Box>
          );
        }}
      >
        <MenuItem value="Non-Vegetarian Food">
          <Checkbox checked={selectedWorkshops.indexOf("Non-Vegetarian Food") > -1} />
          <ListItemText primary="Non-Vegetarian Food" />
        </MenuItem>
        <MenuItem value="Vegetarian Food">
          <Checkbox checked={selectedWorkshops.indexOf("Vegetarian Food") > -1} />
          <ListItemText primary="Vegetarian Food" />
        </MenuItem>
      </Select>
    </FormControl>
  </Grid>

  <Grid
      item
      xs={12}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        overflowX: "hidden", // Hide horizontal scrollbar
        marginLeft: "-8%", // Adjusted margin
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={accept}
            onChange={handleAcceptChange}
            name="accept"
            color="primary"
          />
        }
        label={
          <Link to="/TermsAndCondition" style={{ textDecoration: "none", color: "inherit" }}>
            Accept{" "}
            <b style={{ color: "#D92445", textDecoration: "underline #D92445" }}>
              Terms & Condition
            </b>
          </Link>
        }
      />
      <div style={{ borderLeft: "1px solid black", height: "50px", marginLeft: "10px" }}></div>
      <div style={{ marginLeft: "10px", fontSize: "25px", color: "black" }}>
        ₹41300{" "}
        <b style={{ color: "#337ab7", fontSize: "12px", fontWeight: "bold" }}>(including GST)</b>
        <div
          style={{
            fontSize: "12px",
            color: "#D92445",
            marginTop: "5px",
            textDecoration: "underline #D92445",
            cursor: "pointer",
          }}
          onClick={() => handleAlertOpenTwo(orderDetailsContent)}
        >
          View Details
        </div>
        <Dialog
          open={opentwo}
          onClose={handleAlertCloseTwo}
          PaperProps={{
            style: {
              maxWidth: "600px",
              width: "100%",
              maxHeight: "100%",
              height: "auto",
              position: "absolute",
              top: "20%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <DialogTitle
            style={{
              backgroundColor: "#3e4095",
              color: "white",
              fontSize: "21px",
            }}
          >
            {dialogContenttwo.title}
            <IconButton
              aria-label="close"
              onClick={handleAlertCloseTwo}
              style={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "white",
              }}
            >
              <img
                src={closeIcon}
                alt="close"
                style={{ width: "24px", height: "24px" }}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Typography component={"div"}>{dialogContenttwo.body}</Typography>
          </DialogContent>
        </Dialog>
      </div>
      <button
        style={{
          height: "52px",
          width: "200px",
          backgroundColor: "#3e4095",
          border: "1px solid",
          color: "white",
          fontSize: "18px",
          borderRadius: "8px",
          marginLeft: "10px",
          cursor: "pointer",
        }}
      >
        Register
      </button>
    </Grid>


            </Grid>

          </form>
        </Grid>
      </div>
    </>
  );
}

export default RegistrationNow;
