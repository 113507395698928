import React from 'react';
import CustomRoutes from '../Routes/Routes';
import Nevbar from '../common/Nevbar';
import Footer from '../common/Footer';
import { useLocation } from 'react-router-dom';
import RegistrationNow from '../common/Ui/RegistrationNow';
import RegistrationOnline from '../common/Ui/RegistrationOnline';

const layoutStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
};

const contentStyle = {
    flex: 1
};

function Layout() {
    const location = useLocation();
    const isSpecialPage = location.pathname === '/PrivacyPolicy' || location.pathname === '/TermsAndCondition';

    // Determine if RegistrationOnline page
    const isRegistrationOnlinePage = location.pathname === '/RegistrationOnline';

    return (
        <div style={layoutStyle}>
            {/* Render Navbar unless it's a special page or RegistrationOnline page */}
            {!isSpecialPage && !isRegistrationOnlinePage && <Nevbar />}
            
            <div style={contentStyle}>
                <CustomRoutes />
                
                {/* Render RegistrationOnline component */}
                {isRegistrationOnlinePage && <RegistrationOnline />}
            </div>
            
            {/* Render Footer unless it's a special page or RegistrationOnline page */}
            {!isSpecialPage && !isRegistrationOnlinePage && <Footer />}
        </div>
    );
}

export default Layout;
