import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoadingSpinner from '../common/Ui/LoadingSpinner';
// import { useSelector } from 'react-redux';

const Home = lazy(() => import('../pages/Home'));
const President_Invitation = lazy(() => import('../pages/General_Info/President_Invitation'));
const Secretary_General = lazy(() => import('../pages/General_Info/Secretary_General'));
const President_Chairman = lazy(() => import('../pages/General_Info/President_Chairman'));
const Capital_City = lazy(() => import('../pages/General_Info/Capital_City'));
const About = lazy(() => import('../pages/General_Info/About'));
const Program_Schedule = lazy(() => import('../pages/Program/Program_Schedule'));
const ICOLD_Members = lazy(() => import('../pages/Program/ICOLD_Members'));
const Advisory_Committee = lazy(() => import('../pages/Program/Advisory_Committee'));
const Organizing_Committee = lazy(() => import('../pages/Program/Organizing_Committee'));
const Technical_Committee = lazy(() => import('../pages/Program/Technical_Committee'));
const Symposium_Workshop = lazy(() => import('../pages/Program/Symposium_Workshop'));
const Sponsors = lazy(() => import('../pages/Sponsors'));
const Benefits = lazy(() => import('../pages/Exhibitors/Benefits'));
const Layout = lazy(() => import('../pages/Exhibitors/Layout'));
const Existing_Exhibitors = lazy(() => import('../pages/Exhibitors/Existing_Exhibitors'));
const Fee_Details = lazy(() => import('../pages/Fee_Details'));
const Contacts = lazy(() => import('../pages/Contacts'));
const Hotels = lazy(() => import('../pages/Accomodation/Hotels'));
const Pre_Conference = lazy(() => import('../pages/Tours/Pre_Conference'));
const PostConferenceTours = lazy(() => import('../pages/Tours/PostConferenceTours'));
const Excursion = lazy(() => import('../pages/Tours/Excursion'));
const Local_Sightseeing = lazy(() => import('../pages/Tours/Local_Sightseeing'));
const Accompanying_Person = lazy(() => import('../pages/Tours/Accompanying_Person'));
const Login = lazy(() => import('../pages/Login'));
const Register = lazy(() => import('../pages/Register'));
const PrivacyPolicy = lazy(() => import('../common/Ui/PrivacyPolicy'));
const TermsAndCondition = lazy(() => import('../common/Ui/TermsAndCondition'));
const RegistrationNow = lazy(() => import('../common/Ui/RegistrationNow'));
const Technical_Workshops = lazy(() => import('../pages/Program/Technical_Workshops '));

const CustomRoutes = () => {
    // const user = useSelector((state) => state.auth);
    return (
      <Suspense fallback={<LoadingSpinner />}>
        
          <Routes>
          <Route exact path = '/' element={<Home/>}/>
        <Route exact path = '/Login' element={<Login/>}/>
        <Route exact path = '/Register' element={<Register/>}/>
        <Route exact path = '/president_invitation' element={<President_Invitation/>}/>
        <Route exact path = '/secretary_general' element={<Secretary_General/>}/>
        <Route exact path = '/president_chairman' element={<President_Chairman/>}/>
        <Route exact path = '/capital_city' element={<Capital_City/>}/>
        <Route exact path = '/about' element={<About/>}/>
        <Route exact path = '/program_schedule' element={<Program_Schedule/>}/>
        <Route exact path = '/icold_members' element={<ICOLD_Members/>}/>
        <Route exact path = '/advisory_committee' element={<Advisory_Committee/>}/>
        <Route exact path = '/organizing_committee' element={<Organizing_Committee/>}/>
        <Route exact path = '/technical_committee' element={<Technical_Committee/>}/>
        <Route exact path = '/symposium_workshop' element={<Symposium_Workshop/>}/>
        <Route exact path = '/sponsors' element={<Sponsors/>}/>
        <Route exact path = '/benefits' element={<Benefits/>}/>
        <Route exact path = '/layout' element={<Layout/>}/>
        <Route exact path = '/existing_exhibitors' element={<Existing_Exhibitors/>}/>
        <Route exact path = '/fee_details' element={<Fee_Details/>}/>
        <Route exact path = '/contacts' element={<Contacts/>}/>
        <Route exact path = '/hotels' element={<Hotels/>}/>
        <Route exact path = '/pre_conference' element={<Pre_Conference/>}/>
        <Route exact path = '/post_conference' element={<PostConferenceTours/>}/>
        <Route exact path = '/excursion' element={<Excursion/>}/>
        <Route exact path = '/local_sightseeing' element={<Local_Sightseeing/>}/>
        <Route exact path = '/accompanying_person' element={<Accompanying_Person/>}/>
        <Route exact path = '/PrivacyPolicy' element={<PrivacyPolicy/>}/>
        <Route exact path = '/TermsAndCondition' element={<TermsAndCondition/>}/>
        <Route exact path = '/RegistrationNow' element={<RegistrationNow/>}/>
        <Route exact path = '/Technical_Workshops' element={<Technical_Workshops/>}/>
       

          </Routes>
         
     
      </Suspense>
    );
  };
   
  export default CustomRoutes;